import crypto from "crypto";
import * as Sentry from "@sentry/vue";
import Jimp from "jimp";
import * as UTIF from "utif";
import Pica from "pica";
import { ALGORITHM, ENC_PASSWORD } from "@/config";
import { getCookie } from "../helper/cookie";
import { SENTRY_LEVEL } from "./constant";

export function getIV() {
  const queryString = getCookie("qs");
  const urlDecoded = decodeURIComponent(queryString).replace(/ /g, "+");
  const position = urlDecoded.search("enc=");
  let encString = urlDecoded.slice(position + 4);
  const parts = encString.split(':');
  return Buffer.from(parts[0], 'base64');
}

export default function regex(regx, value) {
  const regex = new RegExp(regx);
  return regex.test(value);
}

export const isObject = v => typeof v === 'object' && v !== null

export const isEmpty = v =>
  !v ||
  (Array.isArray(v) && v.length === 0) ||
  (isObject(v) && Object.keys(v).length === 0);

export function formatNumberWithComma(value) {
  return value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function removeFormatNumberWithComma(value) {
  return value.toString().replace(/,/g, '');
}

const AusState = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

const removeValue = (arr, index) => {
  arr.splice(index, 1);
};

export const LocationValMap = (val) => {
  // Split location string into array
  let locationArray = val.toUpperCase().split(' ');

  // Init
  let state = '';
  let postCode = '';

  // Detect state
  const isSingleWord = (str) => str.split(' ').length === 1;

  for (const region of AusState) {
    const includesRegion = isSingleWord(region)
      ? locationArray.includes(region)
      : locationArray.join(' ').includes(region);

    if (includesRegion) {
      state = region.toUpperCase();
      break; // exit the loop early if a match is found
    }
  }

  // Remove value if valid
  if (state !== '') {
    if (isSingleWord(state)) {
      removeValue(
        locationArray,
        locationArray.findIndex((value) => value === state),
      );
    } else {
      locationArray = val
        .toUpperCase()
        .replace(state, '')
        .split(' ')
        .filter((string) => string);
    }
  }

  // Detect postcode and remove value if valid
  locationArray.forEach((value, index) => {
    if (!isNaN(+value)) {
      postCode = value;
      removeValue(locationArray, index);
    }
  });

  // Create surbub value with the rest of splitted location string array
  const suburb = locationArray.join(' ').toUpperCase();

  return {
    suburb,
    state,
    postCode,
  };
};

function cipher(mode, key, iv, data) {
  const encipher = crypto[mode](ALGORITHM, key, iv);
  let encoded  = encipher.update(data);

  encoded += encipher.final();
  return encoded;
}

export const decrypt = (data, key = ENC_PASSWORD, iv = getIV()) => {
  return cipher('createDecipheriv', key, iv, data);
}

export const b64dec = (data) => {
  return Buffer.from(data, 'base64');
}

export function apiEndpointTransform(hostname) {
  if (['localhost', 'autoflip.co.nz'].some(host => hostname.includes(host)))
    return process.env.VUE_APP_BASE_API_ENDPOINT

  const isNotProd = hostname.includes('psp-')
  let backendHost = ''
  if (isNotProd) {
      backendHost = `api.${hostname}`
  } else {
      backendHost = hostname.replace(/sell|mycar/gi, "api");
  }
  return `https://${backendHost}`
}

export function logError(message) {
  console.error(message);
  Sentry.captureMessage(message, SENTRY_LEVEL.ERROR);
}

export async function tiffToBlob(file) {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onload = async () => {
      try {
        const tiffData = new Uint8Array(fileReader.result);

        // Decode the TIFF file using UTIF
        const pages = UTIF.decode(tiffData);
        UTIF.decodeImage(tiffData, pages[0]); // Decode the first page

        const { width, height, data } = pages[0];

        // Create a new Jimp image using the decoded data
        const jimpImage = new Jimp({ width, height, data });

        // Get a buffer in PNG format and convert it to Blob
        const imageBuffer = await jimpImage.getBufferAsync(Jimp.MIME_JPEG);
        const blob = new Blob([imageBuffer], { type: Jimp.MIME_JPEG });

        resolve(blob);
      } catch (error) {
        reject(error);
      }
    };

    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(file);
  });
}

export async function resizeImage(imageFile) {
  const pica = new Pica();
  let quality = 0.9; // Initial quality
  const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
  const maxSize = 1920; // Maximum width or height

  // Create an Image element from the File or Blob
  const imageElement = await loadImageFromFile(imageFile);

  // Set the target dimensions, preserving the aspect ratio
  let { width, height } = imageElement;
  if (width > height) {
    if (width > maxSize) {
      height = Math.round((height * maxSize) / width);
      width = maxSize;
    }
  } else {
    if (height > maxSize) {
      width = Math.round((width * maxSize) / height);
      height = maxSize;
    }
  }

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  try {
    let resizedBlob;
    do {
      // Resize the image using Pica
      await pica.resize(imageElement, canvas);

      // Convert the resized canvas to a Blob with specified quality
      resizedBlob = await pica.toBlob(canvas, "image/jpeg", quality);

      // Decrease quality if the file size exceeds the limit
      quality -= 0.1;
    } while (resizedBlob.size > maxFileSize && quality > 0);

    return resizedBlob;
  } catch (error) {
    console.error("Error resizing image:", error);
    throw error;
  }
}

function loadImageFromFile(imageFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = reader.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(imageFile);
  });
}
